import React from 'react';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';

export default class ProductItemPlaceholder extends React.Component { 


    render() {
        return (
            <div className={`cafe-item js-item item-skeleton`}>
                <main >
                    <div className="cafe-item-photo">
                        <picture className="cafe-item-lottie js-item-lottie image-skeleton skeleton">
                            <div></div>
                        </picture>
                    </div>
                    <div className="cafe-item-label brand">
                        <span className="cafe-item-maker maker-skeleton skeleton"></span>
                    </div>
                    <div className="cafe-item-label">
                        <span className="cafe-item-title bold title-sekleto skeletonn"></span>
                    </div>
                </main>
                <span className="priceBlock">
                    <span className="cafe-item-price price-skeleton skeleton">
                    </span>
                </span>
                <div className={`cafe-item-buttons buttons-skeleton skeleton`}>
                </div>
            </div>
        );
    }

}