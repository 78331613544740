import React from 'react';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { Link } from "react-router-dom";

import eventBus from "../services/eventBus";

import { connect } from "react-redux";
import CartService from '../services/cart-service';

class ProductItem extends React.Component { 

    constructor(props){
        super(props);
        this.red = "#FF5D5E";
        this.white = "#ffffff";
        this.state = {
            count: 0,
            style: '',
            label: 'Add to cart',
            isFaved: props.userService.hasItem(props.productId),
            tooltipStyle: {display: 'none',}
        }

        this.o_increment = this.props.increment;
        this.o_decrement = this.props.decrement;
        this.o_addToList = this.props.addToList;
        this.o_addToCart = this.props.addToCart;
        this.o_removeFromCart = this.props.removeFromCart;
        
        if(this.props.count < 1 || this.props.count == 'pcs') {
            this.state = {
                ...this.state, 
                label: 'Out of stock',
            }
        }

        const callbackLike = (data) => {
            if(data.id == props.productId) {
                this.setState({
                    ...this.state,
                    isFaved: data.like,
                });
            
                if(data.like) {
                    this.props.favoriteAdd(props.productId);
                } else {
                    this.props.favoriteRemove(props.productId); 
                }
            }
        };
        eventBus.subscribe('productLike', callbackLike);

            this.o_addToList(props.productId);
            const callbackProductAdd = (data) => {
                if(data.id == props.productId) {
                    this.addItem();
                }
            };
            eventBus.subscribe('productAdd', callbackProductAdd);
            
            const callbackProductRemove = (data) => {
                if(data.id == props.productId) {
                    this.removeItem();
                }
            };
            eventBus.subscribe('productRemove', callbackProductRemove);
        
    }

    favHook() {
        if(this.props.userService.hasItem(this.props.productId)) {
             this.setState({
                ...this.state,
                isFaved: true,
            });
        } else {
            this.setState({
                ...this.state,
                isFaved: false,
            });
        }
    }

    getCountState() {
        return this.state.count;
    }


    addItem() {
        if(this.props.count < 1 || this.props.count == 'undefined' || this.props.count == 'pcs') {
            return;
        }
        
        // if(this.state.count >= this.props.count) {
        //     return;
        // }
        if(this.props.counter[this.props.productId] >= this.props.count) {
            return;
        }
        
        this.setState({
            count: this.props.counter[this.props.productId]+1,
            style: 'non-empty',
            label: '+'
        })
        
        // this.props.addToCart(this.props.mapping_productId, this.props.price, this.props.title, 1, (this.props.images.data != null ? this.props.images.data[0].attributes.url : ''), this.props.stripe_token.split('_')[1] );
        this.o_addToCart(this.props.mapping_productId, this.props.price, this.props.title, (this.props.images.data != null ? this.props.images.data[0].attributes.url : ''), this.props.stripe_token.split('_')[1] );
        this.o_increment(this.props.productId);
        // <button className="button" onClick={decrement(this.props.productId)}>
        //             Decrement
        //         </button>
        
    }
    
    removeItem() {
        if(this.props.counter[this.props.productId] > 1) {
            this.setState({
                count: this.state.count-1,
                style: this.state.style,
                label: this.state.label
            })
        }else {
            this.setState({
                count: this.props.counter[this.props.productId]-1,
                style: '',
                label: (this.props.count < 1 || this.props.count == 'pcs') ? 'Out of stock' : 'Add to cart',
            })
        }
        // this.props.removeFromCart(this.props.title, 1);
        this.o_removeFromCart(this.props.title);
        this.o_decrement(this.props.productId);
    }

    updateFav(pid) {
        if(!this.props.user) {
            this.setState({
                ...this.state,
                tooltipStyle: {display: 'block',},
            });

            setTimeout(_ => {
                this.setState({
                    ...this.state,
                    tooltipStyle: {display: 'none',},
                });
            }, 1500);
            return;
        }
        if(this.props.userService.hasItem(this.props.productId)) {
            this.setState({
                ...this.state,
                isFaved: false,
            });
            this.props.favoriteRemove(pid); 
        } else {
            this.props.favoriteAdd(pid);
            this.setState({
                ...this.state,
                isFaved: true,
            });
        }

    }

    getProductLink() {
        return "/" + (process.env.SUBFOLDER ? process.env.SUBFOLDER : '') + "product/" + this.props.title.replace(/\s/g, '-').substring(0, 100);
    }


    render() {

        return (
            <div key={this.props.title} className={`cafe-item js-item ${this.props.counter[this.props.productId] > 0 ? 'non-empty' : ''}`} data-item-id={this.props.productId} data-item-price="{this.props.price}">

                <header>
                        <a className="like" onClick={(e) => { e.preventDefault(); this.updateFav(this.props.productId);}}>
                            <div className="app_tooltip" style={this.state.tooltipStyle}>Login or signup to use favorites</div>
                            <svg fill={this.state.isFaved ? this.red : this.white} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.69001C2 5.60001 4.49 3.10001 7.56 3.10001C9.38 3.10001 10.99 3.98001 12 5.34001C13.01 3.98001 14.63 3.10001 16.44 3.10001C19.51 3.10001 22 5.60001 22 8.69001C22 15.69 15.52 19.82 12.62 20.81Z" stroke={this.state.isFaved ? this.red : "#D1D1D1"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>

                    
                </header>
                {/*  */}
                <Link to={this.getProductLink()}  onClick={(e) => {e.preventDefault(); this.props.showModal(this.props.productId)}}>
                <main >

                    <div className="cafe-item-photo">
                        <picture className="cafe-item-lottie js-item-lottie">
                            {/* <img src={"images/" + this.props.img} */}
                            <img src={this.props.images.data != null ? this.props.images.data[0].attributes.url : '' }
                                alt={this.props.title} />
                        </picture>
                    </div>
                    <div className="cafe-item-label brand">
                        <span className="cafe-item-maker">{this.props.product_brand ? this.props.product_brand : 'Brand'}</span>
                    </div>
                    <div className="cafe-item-label">
                        <span className="cafe-item-title bold">{this.props.title}</span>
                    </div>
                </main>
                </Link>
                <span className="priceBlock" onClick={() => this.props.showModal(this.props.productId, this.modal)}>
                    <span className="cafe-item-price">€{this.props.price}
                        {this.props.discount_price &&
                            <span className="old-price">
                                €{this.props.discount_price}
                            </span>
                        }
                    </span>
                    <span className="cafe-item-unit">{this.props.unit} {this.props.unit_label}</span>
                </span>
                <div className={`cafe-item-buttons ${(!Object.keys(this.props.counter).includes(this.props.productId) || this.props.counter[this.props.productId] < 1 || this.props.count == 'pcs') ? "single" : ""}`}>
                    {this.props.counter[this.props.productId] > 0 &&
                        <>
                            <button className="cafe-item-decr-button js-item-decr-btn button-item ripple-handler" onClick={() => {this.removeItem()}}>
                                <span className="ripple-mask"><span className="ripple"></span></span>
                            </button>

                            <div className={`cafe-item-counter js-item-counter`}>{this.props.counter[this.props.productId]}</div>
                            
                            <button className="cafe-item-incr-button active js-item-incr-btn button-item ripple-handler"
                                onClick={() => {this.addItem()}}>
                                {/* <span className="button-item-label">{this.state.label}</span> */}
                                <span className="ripple-mask"><span className="ripple"></span></span>
                            </button>
                        </> 
                    }

                    {(this.props.counter[this.props.productId] == undefined || this.props.counter[this.props.productId] < 1 || this.props.count == 'pcs') &&
                        <>
                            <button className={`add-button ${ this.props.count == 'pcs' || this.props.count == undefined || this.props.count < 1 ? 'disabled' : ''} cafe-item-incr-button js-item-incr-btn button-item ripple-handler relative`}
                                onClick={() => {this.addItem()}}>
                                <span className={`button-item-label`}>{this.state.label}</span>
                                <span className="ripple-mask"><span className="ripple"></span></span>
                            </button>
                        </>
                    }
                </div>

                {/* dd:{this.props.product.state.count} */}
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => ({
    increment: (id) => {
        dispatch({ type: "INCREMENT", payload: {id: id}})
    },
    decrement: (id) => {
        dispatch({ type: "DECREMENT", payload: {id: id} });
    },
    addToList: (id) => {
        dispatch({ type: "ADDTOLIST", payload: {id: id} });
    },
    addToCart: (id, price, title, imgUrl, stripe_token) => {
        dispatch({ type: "ADDTOCART", payload: {id: id, price: price, item: title, image: imgUrl, stripe_token: stripe_token} });
    },
    removeFromCart: (title) => {
        dispatch({ type: "REMOVEFROMCART", payload: {title: title} });
    },
});

const mapStateToProps = (state) => ({
    counter: state.counter.counter,
    listAdded: state.counter.listAdded,
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);