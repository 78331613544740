export default function ReviewScreen(props) {
    // ${props.showLoginScreen ? "visible": ""}
    return(
        <>
            <section className={`login-popup visible`} onSubmit={props.reviewAdd}>
                <form className="login-form">
                    <h2>Review</h2>
                    message: <input type="text" value={props.review} onChange={(e) => props.setReview(e.target.value)} />
                    <button type="submit">Send</button>
                </form>
            </section>
            <section>
            {props.reviewList && props.reviewList.map((item) => (
                <div>{item.message}</div>
            ))}
            </section>
        </>
    );
}