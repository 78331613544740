import AppHeader from './AppHeader.js';
import { useEffect, useState } from 'react';
import successIcon from '../assets/ui/Success Icon.png';
import anchorSVG from '../assets/ui/anchor.svg';
import { Link } from "react-router-dom";
import eventBus from "./../services/eventBus";

export default function ThankyouScreen(props) {
    
    const orderNum = window.location.search.slice(1).split('&')[0].split('=')[1];
    const [orderSum, setOrderSum] = useState(0);
    const [orderDate, setOrderDate] = useState('');
    
    const orderFetched = (order) => {
        let oSum = 0;
        console.log("order:");
        console.log(order.res[0]);
        order.res.forEach(el => {
            oSum += el.item_single_price * el.count;
        });
        setOrderSum(oSum);

        let oDate = new Date(order.res[0].createdAt);
        let oTime = oDate.toISOString().split("T")[1].split('.')[0];
        oDate = oDate.toISOString().split("T")[0];
        setOrderDate(oDate + " " + oTime);
    };
    
    useEffect(() => {
        console.log(orderNum);
        props.getOrderList(orderNum);
        eventBus.subscribe('orderFetched', orderFetched);
    }, [])

    return(
        <div className="cafe-screen thank-you-page">
            {/* <div className="header-wrapper" style={{backgroundColor: '#FAFAFA', paddingBottom: '1px', borderBottom: '1px solid #E6E6E6', marginBottom: "20px"}}> */}
            <div className="header-wrapper" style={{paddingBottom: '1px', borderBottom: '1px solid #E6E6E6', marginBottom: "20px"}}>
                <AppHeader  logout={props.logout} handleLogin={props.handleLogin} user={props.user} />

                <div className='header-store-name'>
                    <p>Dr. Badi</p>
                </div>
            </div>

            <div>
                <img src={successIcon} />
                <section>
                    <p style={{fontWeight: '600', marginBottom: '0'}}>Payment success!</p>
                    <p>Your payment has been successfully done.</p>
                    
                    <div className='purchased-items'>
                            <div className="purchased-item">
                                <main>
                                    <section>
                                        <p>
                                            <span className='left-part'>Amount</span>
                                            <span className='right-part amount'>€ {orderSum.toFixed(2) && <>{orderSum}</>}</span>
                                        </p>
                                        <p>
                                            <span className='left-part'>Payment status</span>
                                            <span className='right-part payment-status success'>success</span>
                                        </p>
                                    </section>
                                    <div class="divider"></div>
                                    <section>
                                        <p>
                                            <span className='left-part'>Order Number</span>
                                            <span className='right-part'>#{orderNum}</span>
                                        </p>
                                        <p>
                                            <span className='left-part'>Merchant name</span>
                                            <span className='right-part'>Dr.Badi OÜ</span>
                                        </p>
                                        <p>
                                            <span className='left-part'>Payment Method</span>
                                            <span className='right-part'>Credit Card</span>
                                        </p>
                                        <p>
                                            <span className='left-part'>Payment Time</span>
                                            <span className='right-part'>{orderDate}</span>
                                        </p>
                                    </section>
                                </main>            
                            </div>
                    </div>

                    <section className="thank-you-btn-section">
                        <button className="thank-you btn btn-receipt btn-pay"><img src={anchorSVG} />Get PDF Receipt</button>
                        <Link to={"/" + (process.env.SUBFOLDER ? process.env.SUBFOLDER : '')}>
                            <button className="thank-you btn btn-done btn-pay">Done</button>
                        </Link>
                    </section>
                </section>
            </div>

        </div>
    );
}