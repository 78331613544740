import { useState } from 'react';

import LoginScreen from './LoginScreen.js'
import jwt_decode from 'jwt-decode';
import axios from 'axios';

export default function AppHeader(props) {
    let url = '';

    const manageBilling = async (pid) => {
        if (process.env.NODE_ENV === 'production') {
          url = process.env.REACT_APP_PROD_API_URL;
        } else if (process.env.NODE_ENV === 'development') {
          url = process.env.REACT_APP_DEV_API_URL;
        }
        
        const decodedToken = jwt_decode(props.user);

        try {
            await axios.post(url + '/user/manage-billing', { token: decodedToken.stripe_token }).
            then(response => {
              if (response.status === 200) {
                window.location.href = response.data.url; // redirect to dashboard page
              }
            })
            .catch(error => console.error(error));
          } catch (err) {
            //setError(err.response.data.message);
          }

      };
 
    return(
        <nav className="header-nav">
            {
                (props.user) ? (
                    <>
                        <a href="#" onClick={(e) => {e.preventDefault(); props.logout()}}>Logout</a>
                        <a href="#" onClick={(e) => {e.preventDefault(); manageBilling()}}>Profile</a>
                    </>
                )  : (
                    <>
                        <a href="#" onClick={(e) => {e.preventDefault(); document.location.href = '/' + (process.env.SUBFOLDER ? process.env.SUBFOLDER : '') + 'login';}}>Login</a>
                        <a href="#" onClick={(e) => {e.preventDefault(); document.location.href = '/' + (process.env.SUBFOLDER ? process.env.SUBFOLDER : '') + 'signup';}}>Sign up</a>
                    </>
                )
            }
        </nav>
    );
}