import React from 'react';
import eventBus from "./../services/eventBus";

export default class Cart extends React.Component { 

    constructor(props = {}) {
        super(props);

        this.state = {
            items: props.items || [],
            cartShow: props.cartShow,
            cartService: props.cartService,
            total_sum: 0,
            showViewCart: false,
            scrolled: false,
        };
        
        const productAdded = (obj) => {
            if(!this.state.scrolled) {
                setTimeout(_ => {
                    if(document.querySelector('.btn-vieworder')) {
                      document.querySelector('.btn-vieworder').scrollIntoView();
                    }
                  }, 50);
            }

            this.setState({
                ...this.state,
                total_sum: this.state.total_sum + parseFloat(obj.price),
                showViewCart: true,
                scrolled: true,
            });
        }
        
        const productRemoved = (obj) => {
            this.setState({
                ...this.state,
                total_sum: this.state.total_sum - obj.price,
                showViewCart: this.state.total_sum - obj.price > 0 ? true : false,
                scrolled: this.state.total_sum - obj.price > 0 ? true : false,
            });
        }

        eventBus.subscribe('productAdded', productAdded);
        eventBus.subscribe('productRemoved', productRemoved);
   }

    render() {
        let div = '';
        // if(this.props.showViewCart) {
        if(this.state.showViewCart) {
            div = <div className="button" onClick={this.state.cartShow}><a className="btn btn-vieworder">
                <span className="btn-vieworder-text">View order</span>
                <span className='checkout-sum'>€{this.state.total_sum.toFixed(2)}</span>
                </a></div>
        }
        return <div className="button-wrapper">
            {div}
        </div>
    }

}