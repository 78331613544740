import './App.css';
import React from 'react';

import './assets/css/cafe.css';
import './assets/css/bootstrap.css';
import './assets/css/modal.css';
import './assets/css/custom.css';


/*End MelieSearch*/


import LoginScreen from './components/LoginScreen.js';
import ForgotPassword from './components/ForgotPassword.js';
import ResetPassword from './components/ResetPassword.js';
import ThankyouScreen from './components/ThankyouScreen.js';
import ReviewScreen from './components/ReviewScreen.js';
import RegistrationScreen from './components/RegistrationScreen.js';
import UserService from './services/user-service.js';
import ProductService from './services/product-service.js';
import eventBus from "./services/eventBus";

import ProductList from './components/ProductList.js';
import TagItem from './components/TagItem';

import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import queryString from "query-string";

import formbricks from "@formbricks/js";

//import { Navigate } from "react-router";

import { Link, Routes, Route, useNavigate, Navigate, BrowserRouter, useParams  } from "react-router-dom";

import jwt_decode from 'jwt-decode';

var inited1 = false;

if (typeof window !== "undefined") {
  formbricks.init({
    environmentId: "clml7yhmu0aawry0g9kgrkn74",
    apiHost: "https://app.formbricks.com",
    debug: true, // remove when in production 
  });
}



function App() {
  var url;

  // let navigate = useNavigate();

  const [orderList, setOrderList] = useState([]);

  const [user, setUser] = useState(null);

  const [reviews, setReviews] = useState(null);
  const [review, setReview] = useState('');

  const [userFavorites, setUserFavorites] = useState([]);

  const [usernameLog, setUsernameLog] = useState('');
  const [passwordLog, setPasswordLog] = useState('');

  const [usernameReg, setUsernameReg] = useState('');
  const [passwordReg, setPasswordReg] = useState('');

  const [error, setError] = useState('');

  const [reviewList, setReviewList] = useState(null);

  var pos;
  var dragging = false;
  const mouseDownHandler = (e) => {
    dragging = true;
      pos = {
        // The current scroll
        left: document.querySelector('.tags').scrollLeft,
        top: document.querySelector('.tags').scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
    };
  }

  const [productIdParam, setProductIdParam] = useState(null);

  
  function AppRoute() {

    // document.querySelector('meta[property="og:description"]').content = 'Test1';
    // document.querySelector('meta[property="og:title"]').content = 'test2';
    // document.querySelector('meta[property="og:image"]').content = 'https://www.placecage.com/c/460/300';
    // document.querySelector('meta[property="og:url"]').content = 'https://hahaha';
  
    

    return(
      <div className="App">
        

        <ProductList
            productService={productService}
            handleLogin={handleLogin}
            logout={logout}
            user={user}
            mobileScroll={mobileScroll}
            mouseDownHandler={mouseDownHandler}
            mouseMoveHandler={mouseMoveHandler}
            mouseUpHandler={mouseUpHandler}
            userService={userService}
            userFavorites={userFavorites}
            favoriteAdd={favoriteAdd}
            favoriteRemove={favoriteRemove}
            productRefs={productRefs}
            saveOrderToDB={saveOrderToDB}
            reviewList={reviewList}
            review={review}
            setReview={setReview}
            reviewAdd={reviewAdd}
            ratingAdd={ratingAdd}
            />
      </div>
    );
  }

  const mouseUpHandler = (e) => {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    document.querySelector('.tags').scrollLeft = pos.left - dx;
    dragging = false;
  }
  
  const mouseMoveHandler   = (e) => {
    if(!dragging) {
      return;
    }
    const dx = e.clientX - pos.x;
    
    // Scroll the element
    let diff = pos.left - dx;
    document.querySelector('.tags').scrollLeft = diff;
    if(diff < 10) {
      document.querySelector('.arrow-left').style.display = 'none';
    } else {
      document.querySelector('.arrow-left').style.display = 'flex';
    }
    
    if(diff >= document.querySelector('.tags').scrollWidth - document.querySelector('.tags').clientWidth) {
      document.querySelector('.arrow-right').style.display = 'none';
    } else {
      document.querySelector('.arrow-right').style.display = 'flex';
    }
  }

  const mobileScroll = (e) => {
    if(document.querySelector('.tags').scrollLeft > 30) {
      document.querySelector('.arrow-left').style.display = 'flex';
    } else {
      document.querySelector('.arrow-left').style.display = 'none';
    }

    if(document.querySelector('.tags').scrollLeft >= document.querySelector('.tags').scrollWidth - document.querySelector('.tags').clientWidth) {
      document.querySelector('.arrow-right').style.display = 'none';
    } else {
      document.querySelector('.arrow-right').style.display = 'flex';
    }
  }

  const moveRight = (e) => {
    document.querySelector('.tags').scrollLeft = document.querySelector('.tags').scrollLeft + 50;
  }
  
  const moveLeft = (e) => {
    document.querySelector('.tags').scrollLeft = document.querySelector('.tags').scrollLeft - 50;
  }

  const handleLogin = async (e) => {
    
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    e.preventDefault();
    try {
      const res = await axios.post(url + '/user/login', { username: usernameLog, password: passwordLog }).
      then(response => {
        if (response.status === 200) {
          var result = response.data.user.map(obj => obj.pid);
          userService.setFavorites(result);
          setUserFavorites(result);

          localStorage.setItem('token', response.data.token);
          setUser(response.data.token);
          window.location.href = '/'; // redirect to dashboard page
        }
      })
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const [linkSent, setLinkSent] = useState(false);

  const resetPassword = async (e) => {
    
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    e.preventDefault();
    try {
      const res = await axios.post(url + '/user/reset_password', { username: usernameLog }).
      then(response => {
        if (response.status === 200) {
          setLinkSent(true);
        }
      })
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const getFavs = async(e) => {
    
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    if(user) {
      try {
        const decodedToken = jwt_decode(user);
       
        const res = await axios.post(url + '/user/get_favorites', { uid: decodedToken.uid }).
        then(response => {
          if (response.status === 200) {
            console.log(response);
            var result = response.data.data.map(obj => obj.pid);
            userService.setFavorites(result);
            // setUserFavorites(result);
            // localStorage.setItem('token', response.data.token);
          }
        })
        .catch(error => console.error(error));
      } catch (err) {
        if(err.response) {
          setError(err.response.data.message);
        } else {
          console.log(err);
        }
      }
    }
  };

  const handleRegistration = async (e) => {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }
    
    e.preventDefault();
    try {
      const res = await axios.post(url + '/user/signup', { username: usernameReg, password: passwordReg })
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token);
          setUser(response.data.token);
          window.location.href = '/'; // redirect to dashboard page
        }
      })
      .catch(error => {
        console.error(error);
        setError(error.response.data.message);
      });
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const favoriteAdd = async (pid) => {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }
    
    try {
      const decodedToken = jwt_decode(user);

      const res = await axios.post(url + '/user/add_favorite', { uid: decodedToken.uid, pid: pid })
      .then(response => {
        if (response.status === 200) {
          userFavorites.push(pid);
          userService.addToFavorites(pid);
          setUserFavorites(userFavorites);
        }
      })
      .catch(error => console.error(error));
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const reviewAdd = async (e, productId) => {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }
    
    if(e) {
      e.preventDefault();
    }

    try {
      const decodedToken = jwt_decode(user);

      const res = await axios.post(url + '/user/add_review', { uid: decodedToken.uid, message: review, productId: productId })
      .then(response => {
        if (response.status === 200) {
          
        }
      })
      .catch(error => console.error(error));
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const ratingAdd = async (e, productId, rating = 0) => {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }
    
    if(e) {
      e.preventDefault();
    }

    try {
      const decodedToken = jwt_decode(user);

      const res = await axios.post(url + '/user/add_rating', { uid: decodedToken.uid, productId: productId, rating: rating })
      .then(response => {
        if (response.status === 200) {
          
        }
      })
      .catch(error => console.error(error));
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const favoriteRemove = async (pid) => {
    
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    try {
      const decodedToken = jwt_decode(user);

      const res = await axios.post(url + '/user/remove_favorite', { uid: decodedToken.uid, pid: pid })
      .then(response => {
        if (response.status === 200) {
          userFavorites.splice(userFavorites.indexOf(pid), 1);
          userService.removeFavorite(pid);
          setUserFavorites(userFavorites);
        }
      })
      .catch(error => console.error(error));
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const logout = async (e) => {

    localStorage.removeItem("favorites");
    setUserFavorites(null);

    localStorage.removeItem("token");
    setUser(null);

    window.location.href = '/'; // redirect to dashboard page
        
  };
  

  const [userService, setUserService] = useState(new UserService());
  const [productService, setProductService] = useState(new ProductService());

  const cartRef = React.createRef();
  const productRefs = useRef();
  productRefs.current = [];
  let allProducts = [];


  const getReviewInfo = async () => {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    let reviewListResult = [];

    try {
			const res = await axios.get(url + '/reviews');
      reviewListResult = res.data;
		} catch (err) {
			console.log(err);
		}
    
    setReviewList(reviewListResult);

	};

  const saveOrderToDB = async (items, comments = '') => {

    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    url = url + '/add';

    try {
      const res = await axios.get(url, { params: {items: items, comments: comments} });
      var currentOrderIdFromResult = res.data.order_id;
		} catch (err) {
			console.log(err);
		}

    return currentOrderIdFromResult;
  
	};

  const updateOrderToDB = async (id, h, stripe_session) => {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }
    // url = 'http://localhost:3001/update';
    url = url + '/update';

    try {
      const res = await axios.get(url, { params: {order_id: id, hash: h, stripe_session: stripe_session} });
		} catch (err) {
			console.log(err);
		}
 
	};

  const getOrderList = async (id) => {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    url = url + '/getOrderList';

    try {
      const res = await axios.post(url, { params: {order_id: id} });
      setOrderList(res.data.data);
      eventBus.emit('orderFetched', {res: res.data.data});
		} catch (err) {
			console.log(err);
		}
 
	};

  useEffect(() => {

    const token = localStorage.getItem('token');
    const favorites = localStorage.getItem('favorites');

    if (token) {
      setUser(token);
    }

    if (favorites) {
      setUserFavorites(JSON.parse(favorites));
    }


    getReviewInfo();

    const queryParams = queryString.parse(window.location.search);

    // console.log('queryParams.id ' + queryParams.order_id);
    // console.log('queryParams.h ' + queryParams.h);

    if(queryParams.order_id != undefined && queryParams.h != undefined) {
      updateOrderToDB(queryParams.order_id, queryParams.h, queryParams.id);
    }

    // const updateTags = (tag) => {
    //   console.warn("Updating tags!");
    //   console.log("Current tags:", tags);
    //   let funcTags = tags;
    //   if(funcTags[0] == 'All') {
    //     funcTags = [];
    //   }
    //   if(!funcTags.includes(tag.tag)) {
    //     funcTags.push(tag.tag);
    //   }else{
    //     funcTags = funcTags.filter(item => item !== tag.tag);
    //     if(funcTags.length == 0) {
    //       funcTags = ['All'];
    //       eventBus.emit('enableTag', {tag: "All"});
    //     }
  
    //     console.log("After filtering:");
    //     console.log(funcTags);
    //   }
  
    //   // let funcTags = tags;
    //   // // funcTags = [];
    //   // // funcTags.push(tag.tag);
    //   // funcTags.push(tag);
    //   console.log("D:", funcTags);
    //   setTags(funcTags);
    // };

  }, [])
  
  // if(!inited1) {
  //   inited1  = true;
  //   document.addEventListener('updateTags', updateTags);
  //   // eventBus.subscribe('updateTags', updateTags);
  //   // setTags(["All"]);
  //   console.log("Inittetd");
  // }

  useEffect(() => {
    getFavs();
  }, [user]);


  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())
  // productRefs.current.push(useRef())

  const { productParamId } = useParams();


  function PopupRoute() {
    return AppRoute();
  }

  const [userActivated, setUserActivated] = useState(false);

  async function confirm_email(id, hash) {
    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }
    
    try {
      const res = await axios.post(url + '/user/confirm', { id: id, hash: hash })
      .then(response => {
        if (response.status === 200) {
          setUserActivated(true);
        } else {

        }
      })
      .catch(error => {
        console.error(error);
      });
    } catch (err) {
    }
  }
  
  function ConfirmEmailRoute() {
    const {userId, emailHash} = useParams();
    
    confirm_email(userId, emailHash);
    
    return (<>
      <div className='center'>
        {!userActivated && 
          <>
            <h1>Hold on a sec...</h1>
          </>
        }
        {userActivated &&
          <>
            <h1>Thank you, your account is now active!</h1>
            <Link to="/">
                Go to home page
            </Link>
          </>
        }
      </div>
    </>);
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/" + process.env.REACT_APP_SUBFOLDER} index element={<AppRoute />}/>
        <Route path={"/" + (process.env.REACT_APP_SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '') + "product/:productParamId/"} element={<PopupRoute />}  />
        <Route path={"/" + (process.env.REACT_APP_SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '') + "confirm_email/:userId/:emailHash/"} element={<ConfirmEmailRoute />}  />
        <Route path={"/" + (process.env.REACT_APP_SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '') + "forgot_password"} element={<ForgotPassword linkSent={linkSent} setUsernameLog={setUsernameLog} resetPassword={resetPassword}/>}  />
        <Route path={"/" + (process.env.REACT_APP_SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '') + "new_password/:userId/:hashString/"} element={<ResetPassword />}  />
        <Route path={"/" + (process.env.REACT_APP_SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '') + "reviews"} element={
          <div className="App">
            <ReviewScreen
                reviewList={reviewList}
                reviews={reviews}
                setReviews={setReviews}
                review={review}
                setReview={setReview}
                reviewAdd={reviewAdd}
              />
          </div>
        }/>
        {(user === null) ? (
          <Route path={"/" + (process.env.REACT_APP_SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '') + "login"} element={
            <div className="App">
              <LoginScreen
                handleLogin={handleLogin}
                usernameLog={usernameLog}
                passwordLog={passwordLog}
                setUsernameLog={setUsernameLog}
                setPasswordLog={setPasswordLog}
                error={error}
              />
            </div>
          }/>
         ) : (
          <Route path={"/" + process.env.REACT_APP_SUBFOLDER + "login"} element={
            <Navigate to='/' />
          }/>
        )}
        {(user === null) ? (
          <Route path={"/" + process.env.REACT_APP_SUBFOLDER + "signup"} element={
            <div className="App">
              <RegistrationScreen
                handleRegistration={handleRegistration}
                usernameReg={usernameReg}
                passwordReg={passwordReg}
                setUsernameReg={setUsernameReg}
                setPasswordReg={setPasswordReg}
                error={error}
              />
            </div>
          }/>
        ) : (
          <Route path={"/" + process.env.REACT_APP_SUBFOLDER + "signup"} element={
            <Navigate to='/' />
          }/>
        )}
        <Route path={"/" + process.env.REACT_APP_SUBFOLDER + "success"} element={
            <div className="App">
              <ThankyouScreen logout={logout} productList={productService.getProductList()} handleLogin={handleLogin} getOrderList={getOrderList} orderList={orderList} user={user} />
            </div>
          }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;