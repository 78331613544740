export default function RegistrationScreen(props) {
    
    // ${props.showLoginScreen ? "visible": ""}

    return(
        <>
            <section className={`login-popup visible`} onSubmit={props.handleRegistration}>
                <form className="login-form">
                    <h2>Sign Up</h2>
                    Login: <input type="email" value={props.usernameReg} onChange={(e) => props.setUsernameReg(e.target.value)} />
                    Password: <input type="password" value={props.passwordReg} onChange={(e) => props.setPasswordReg(e.target.value)} />
                    <button type="submit">Sign up</button>
                </form>
                {props.error && <p>{props.error}</p>}
            </section>
        </>
    );
}