import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

export default class UserService {

    constructor() {
        this.favorites = [];
        this.hooks = [];
        this.removeHooks = [];
    }

    setFavorites = (favs) => {
      this.favorites = favs;
    } 

    hasItem = (item_id) => {
      console.log(this.favorites.includes(item_id));
      return this.favorites.includes(item_id);
    }

    removeFavorite = (pid) => {
      let idx = this.favorites.indexOf(pid);
      this.favorites.splice(idx, 1);
    }

    addToFavorites = (item_id) => {
      this.favorites.push(item_id);
    };

}