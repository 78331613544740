import { useEffect, useState } from 'react';
import eventBus from "../services/eventBus";

export default function TagItem(props) {
    
    const [state, setState] = useState({selected: false});

    useEffect(() => {
        setState({selected: props.selected});
        eventBus.subscribe('disableTag', disableTag);
        eventBus.subscribe('enableTag', enableTag);
    }, [])

    const updateTag = async () => {
        // eventBus.emit('updateTags', {tag: props.text});
        if(props.modal) { //do not highligh tags in modal popup
            props.hideModel();
            props.updateTags({tag: props.text});
            eventBus.emit('enableTag', {tag: props.text});
            eventBus.emit('disableTag', {tag: "All"});
            document.querySelector('.tags').scrollLeft = document.querySelector('.tag[data-tag="' + props.text + '"]').offsetLeft - 100;
        }else {
            if(props.text != "All") {
                props.updateTags({tag: props.text});
                
                setState((o) => {return {selected: !state.selected}});

                // if(!state.selected) {
                //     eventBus.emit('disableTag', {tag: "All"});
                // }
            }else if(props.text == "All" && !state.selected) {
                props.updateTags({tag: props.text});

                setState({selected: !state.selected});
            }
        }
    }

    const disableTag = (tag) => {
        if(tag.tag === props.text) {
            setState({selected: false});
        }
    }

    const enableTag = (tag) => {
        if(tag.tag === props.text) {
            setState({selected: true});
        }
    }
 
    return(
        <span data-tag={props.text} className={`tag ${state.selected ? "tag-selected" : ''}`} onClick={updateTag}>
            {props.text == "Favorites" ? <svg fill="transparent" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.69001C2 5.60001 4.49 3.10001 7.56 3.10001C9.38 3.10001 10.99 3.98001 12 5.34001C13.01 3.98001 14.63 3.10001 16.44 3.10001C19.51 3.10001 22 5.60001 22 8.69001C22 15.69 15.52 19.82 12.62 20.81Z" stroke={state.selected ? '#fff' : "#FF5D5E"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg> : props.text}
            
        </span>
    );
}