import React from 'react';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { Link } from "react-router-dom";
import TagItem from './TagItem';
import AppHeader from './AppHeader.js';
import { useState, useEffect, useRef } from 'react';
import SearchBar from './SearchBar.js';
import axios from 'axios';
import Product from '../services/product';
import ProductItem from './ProductItem.js';
import ProductItemPlaceholder from './ProductItemPlaceholder.js';
import TagsPlaceholder from './TagsPlaceholder.js';
import ProductInfoModal from './ProductInfoModal.js';
import CartListModal from './CartListModal.js';
import CartService from '../services/cart-service';
import Cart from './Cart.js';

/*MeiliSearch*/
import "instantsearch.css/themes/algolia-min.css";
import {
  InstantSearch,
  SearchBox,
  Stats,
  Highlight,
  // InfiniteHits,
  connectInfiniteHits
} from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";


import eventBus from "../services/eventBus";

export default function ProductList(props) { 

  // const searchClient = instantMeiliSearch(
  //   "http://65.21.155.46:7700/",
  //   "a433a2d42cba464395e930e9c8b252c0016d8f883c520a2211839117357bdcab"
  // );
  const searchClient = instantMeiliSearch(
    "https://search.dokka.app/",
    "a433a2d42cba464395e930e9c8b252c0016d8f883c520a2211839117357bdcab"
  );
  
  const renderInfiniteHits = (renderOptions, isFirstRender) => {
    const { hits } = renderOptions;
    // console.log(hits);
    return hits.map(hitItem =>
      {console.log(hitItem.id); return <>{productList && productList.map((item) => {return ( tags.some(t => {if(t != ''){ return tags[0] == 'All' ?  true : (tags[0] == 'Favorites' ? props.userService.hasItem(item.id) : item.product_tags.includes(t)); }}) &&  
                      (hitItem.id == item.id &&
                           <ProductItem
                            userService={props.userService}
                            userFavorites={props.userFavorites}
                            user={props.user}
                            favoriteAdd={props.favoriteAdd}
                            favoriteRemove={props.favoriteRemove}
                            ref={props.productRefs.current[item.id-1]}
                            showModal={handleShow}
                            key={item.id}
                            mapping_productId={item.mapping_id}
                            productId={item.id}
                            count={item.count}
                            rating={item.rating}
                            discount_price={item.discount_price}
                            nutrition_table={item.nutrition_table}
                            price={item.price}
                            tgs={item.tgs}
                            img={item.img}
                            title={item.title}
                            product_brand={item.product_brand}
                            unit={item.unit}
                            unit_label={item.unit_label}
                            addToCart={cartService.addToCart}
                            removeFromCart={cartService.removeFromCart}
                            images={item.product_images}
                            stripe_token={item.stripe_token}
                            isFirstRender={isFirstRender}
                          // product={allProducts[0]}
                          />
                          )
                      )})}</>;}
      
    );
  };

  const InfiniteHits = connectInfiniteHits(
    renderInfiniteHits
  );

  const [productList, setProductList] = useState(null);
  const [allTags, setAllTags] = useState(null);
  const [productInfo, setProductInfo] = useState({ id: null, mapping_id: null, stripe_token: null, title: null, img: null, details: null, isFaved: true });
  const [product, setProduct] = useState({});

  const [scrolled, setScrolled] = useState(false);
  
  const [showViewCart, setShowViewCart] = useState(false);

  const Hit = ({ hit }) => (
    <div key={hit.id}>
      <div className="hit-name">
        <Highlight attribute="name" hit={hit} />
      </div>
      <p className="hit-categories"><Highlight attribute="categories" hit={hit} /></p>
      <div className="hit-image">
        <img src={hit.picture} alt={hit.name} width="200px" />
        <p className="image-credit">Picture by <a href={hit.picture_author_profile_link}>{hit.picture_author}</a> on <a href="https://unsplash.com/?utm_source=restaurants_demo&utm_medium=referral">Unsplash</a></p>
      </div>
      <div className="hit-description">
        <Highlight attribute="description" hit={hit} />
      </div>
    </div>
  );

  
  const orderShow = () => {
    // setShowViewCart(true);
    // if(!scrolled) {
      //   setScrolled(true);
      // }
    }
    const orderHide = () => {
      setShowViewCart(false);
      setScrolled(false);
    }
    
  const [cartService, setCartService] = useState(new CartService(orderShow, orderHide));

  const [show, setShow] = useState({ status: false, productId: 0 });
  const handleShow = (itemId) => {
    setShow({ status: true, productId: itemId });
    if(props.userService.hasItem(itemId)) {
      setIsFaved(true);
    } else {
      setIsFaved(false);
    }
    setProductInfo(productList.find(el => el.id === itemId));
    setProduct(allProducts.find(p => p.id == itemId));
    let prod = productList.find(el => el.id === itemId);
    window.history.replaceState(null, "Dr. Badi - " + prod.title, "/" + (process.env.SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '') + "product/" + prod.title.replace(/\s/g, '-').substring(0, 100));
  }

  const handleHide = () => {
    setShow({ status: false, productId: 0 });
  }

  const [isFaved, setIsFaved] = useState(false);
  const updateFav = (val) => {
    setIsFaved(val);
  }

  useEffect(() => {
    getProductInfo();
  }, []);

  let allProducts = [];

  const getProductInfo = async () => {
    let url = '';

    if (process.env.NODE_ENV === 'production') {
      url = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.NODE_ENV === 'development') {
      url = process.env.REACT_APP_DEV_API_URL;
    }

    let productListResult = [];
    let allTags = [];

    try {
			const res = await axios.get(url);
      productListResult = res.data.prods;
      allTags = res.data.tags;
      // console.log('products start');
      // console.log(res.data);
      // console.log('products end');
		} catch (err) {
			console.log(err);
		}
   
    setProductList(productListResult);
    setAllTags(allTags);
    props.productService.setProductList(productListResult);
    productListResult.forEach(el => {
      allProducts.push(new Product(el.id));
    });
 	};

  const [tags, setTags] = useState(['All']);
  const updateTags = (tag) => {
    console.log('updating tags' + tag.tag);
    
    let funcTags = [...tags];
  
    if(tag.tag == 'All') {
      for (let i = 0; i < funcTags.length; i++) {
        eventBus.emit('disableTag', {tag: funcTags[i]});
      }      
      funcTags = ['All'];
    } else if (tag.tag == 'Favorites') {
      for (let i = 0; i < funcTags.length; i++) {
        eventBus.emit('disableTag', {tag: funcTags[i]});
      }
      if(funcTags.includes('Favorites')) {
        funcTags = ['All'];
        eventBus.emit('enableTag', {tag: "All"});
      } else {
        funcTags = ['Favorites'];
      }
    } else {
      eventBus.emit('disableTag', {tag: 'All'});
      eventBus.emit('disableTag', {tag: 'Favorites'});
      if(funcTags[0] == 'All') {
        funcTags = [];
      }
      if(funcTags.includes('Favorites')) {
        funcTags = funcTags.filter(item => item != 'Favorites');
      }
      if(!funcTags.includes(tag.tag)) {
        funcTags.push(tag.tag);
      } else {
        funcTags = funcTags.filter(item => item !== tag.tag);
        if(funcTags.length == 0) {
          funcTags = ['All'];
          eventBus.emit('enableTag', {tag: "All"});
          document.querySelector('.tags').scrollLeft = 0;
        }
      }
    }

    setTags((prevTags) => [...funcTags]);
  };

  useEffect(() => {
    if(window.location.pathname.includes('/product/')) {
      let parts = window.location.pathname.substr(1).split('/');
      let prodId = parts[parts.length - 1];
      if(productList) {
        if(prodId) {
          productList.map(el => console.log(el.url));
          let p = productList.find(el => el.url === prodId);
          handleShow(parseInt(p.id));
        }
      }
    }
  }, [productList]);

  const [showCart, setShowCart] = useState({ status: false });
  
  const cartShow = () => {
    setShowCart({ status: true });
  }
  const cartHide = () => {
    setShowCart({ status: false });
  }


    return (
        <>

          <CartListModal saveOrderToDB={props.saveOrderToDB} cartService={cartService} productList={productList} productRefs={props.productRefs} cartHide={cartHide} cartItems={cartService.getItems()} showCart={showCart}/>
            {productInfo && <ProductInfoModal
              updateTags={updateTags}
              productRefs={props.productRefs}
              productList={productList}
              hideModal={handleHide}
              productInfo={productInfo}
              reviewList={props.reviewList}
              isFaved={isFaved}
              show={show}
              prod={product}
              review={props.review}
              setReview={props.setReview}
              reviewAdd={props.reviewAdd}
              ratingAdd={props.ratingAdd}
              user={props.user}
              updateFav={updateFav}
              productId={productInfo.id}
              userFavorites={props.userFavorites}
              userService={props.userService}
              addToCart={cartService.addToCart}
              cart={cartService} />
            }    

          <section className="homepage-tags wrapper">
                <AppHeader  logout={props.logout} handleLogin={props.handleLogin} user={props.user}/>
                  
                <div className='header-store-name'>
                  <p>Dr. Badi</p>
                </div>

                <InstantSearch indexName="dokka-product" searchClient={searchClient}>
                  <Stats />
                  <SearchBox />

                {/* <SearchBar/> */}

                <div className='tags-container'>
                  <div class="arrow-block arrow-left"> &lt; </div>
                  <section className='tags' onScroll={props.mobileScroll} onMouseDown={props.mouseDownHandler} onMouseMove={props.mouseMoveHandler} onMouseUp={props.mouseUpHandler}>
                    <TagItem updateTags={updateTags} text={"All"} selected={true} />
                    {props.user &&
                      <TagItem updateTags={updateTags} text={"Favorites"} selected={false} />
                    }
                    
                    {allTags && allTags.map(i => {
                      return <TagItem updateTags={updateTags} text={i} selected={false} />
                    })}

                    {!allTags && <>
                        <TagsPlaceholder />
                        <TagsPlaceholder />
                        <TagsPlaceholder />
                        <TagsPlaceholder />
                      </>
                    }

                  </section>
                  <div class="arrow-block arrow-right">&gt;</div>
                </div>

                
                

                    <section className="cafe-page cafe-items">
                      <InfiniteHits  />
                      {/* {productList && productList.map((item) => {return ( tags.some(t => {if(t != ''){ return tags[0] == 'All' ?  true : (tags[0] == 'Favorites' ? props.userService.hasItem(item.id) : item.product_tags.includes(t)); }}) &&
                          <ProductItem
                            userService={props.userService}
                            userFavorites={props.userFavorites}
                            user={props.user}
                            favoriteAdd={props.favoriteAdd}
                            favoriteRemove={props.favoriteRemove}
                            ref={props.productRefs.current[item.id-1]}
                            showModal={handleShow}
                            key={item.id}
                            mapping_productId={item.mapping_id}
                            productId={item.id}
                            count={item.count}
                            rating={item.rating}
                            discount_price={item.discount_price}
                            nutrition_table={item.nutrition_table}
                            price={item.price}
                            tgs={item.tgs}
                            img={item.img}
                            title={item.title}
                            product_brand={item.product_brand}
                            unit={item.unit}
                            unit_label={item.unit_label}
                            addToCart={cartService.addToCart}
                            removeFromCart={cartService.removeFromCart}
                            images={item.product_images}
                            stripe_token={item.stripe_token}
                          // product={allProducts[0]}
                          />
                      )})} */}
                      {!productList && <>
                          <ProductItemPlaceholder/>
                          <ProductItemPlaceholder/>
                          <ProductItemPlaceholder/>
                          <ProductItemPlaceholder/>
                      </>}
                  </section>
              </InstantSearch>

                    
              </section>

              <section className="viewCart">
                <Cart cartShow={cartShow} showViewCart={showViewCart} items={cartService.getItems} cartService={cartService}  />
              </section>
          </>
    );

}