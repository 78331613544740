import { Link } from "react-router-dom";

export default function LoginScreen(props) {
    
    return(
        <>
            <section className={`login-popup visible`} onSubmit={props.handleLogin}>
                <form className="login-form">
                    <h2>Sign In</h2>
                    Login: <input type="email" value={props.usernameLog} onChange={(e) => props.setUsernameLog(e.target.value)} />
                    Password: <input type="password" value={props.passwordLog} onChange={(e) => props.setPasswordLog(e.target.value)} />
                    <button type="submit">Login</button>
                    <p>
                        <Link to="/forgot_password">Forgot password?</Link>
                        <Link to="/">Go back</Link>
                    </p>
                </form>
                {props.error && <p>{props.error}</p>}
            </section>
        </>
    );
}