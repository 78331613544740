import { Modal, Button } from "react-bootstrap";
import React, { useRef, useEffect, useState } from "react";

import { connect } from "react-redux";

function sendPaymentRequest(e, cartService, saveOrderToDB, cartItems) {
    e.preventDefault();
    // const items = cartService.getPrepedItems();
    const items = cartItems;

    // console.log('INIT saveOrderToDB');
    // console.log(JSON.stringify(items));
    
    let comments = document.querySelector('.order-comments').value;
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    const reg = /[&<>"'/]/ig;
    comments = comments.replace(reg, (match)=>(map[match]));
    const result = saveOrderToDB(JSON.stringify(items), comments);
    
    result.then(data => {
        // console.log('AAA');
        // console.log(data);
        // fetch('http://localhost:4242/create-checkout-session', {
        fetch(process.env.REACT_APP_API_URL + '/create-checkout-session', {
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({currentOrderId: data, items: items})
            //items
        }).then(r => r.json())
        .then(response => {
            window.location.href = response.url;
        })
        .catch(function(err) {
            console.info(err + " url: " );
        });
    }).catch(error => {
        console.error(error);
    });

    console.log(result);

    // console.log('AA_RESULT_AA' + result); rows.insertId

    
}

function useOutsideAlerter(ref, props) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            props.cartHide();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

export  function CartInfoModal(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props);

    const totalPrice = props.items.reduce((acc, item) => {
        return acc + (item.product_price * item.count);
    }, 0);

    return (
        <Modal show={props.showCart.status} className="cart-modal">
            <div ref={wrapperRef} class="cart-window">
                <Modal.Header>
                    <Modal.Title>
                        Your order
                    </Modal.Title>

                    <div className="text-green bold btn-edit cart-close" onClick={props.cartHide}>
                        Edit
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="cartItems">
                        {props.items && props.items.map((item) => (
                            <div className="cart-item" key={item.item}>
                                {/* <img className="cart-image" src={"https://drbadi-cms-production.up.railway.app/api/products?populate=" + item.image} /> */}
                                <img className="cart-image" src={item.image} />
                                <div className="item-info">
                                    <b>{item.item}</b> &nbsp; <b className="item-count">{item.count}x</b> <b className="right-column">€{(item.product_price*item.count).toFixed(2)}</b>
                                    {/* (<b onClick={() => {
                                        props.productRefs.current[props.productList.indexOf(props.productList.find(el => el.title === item.item))].current.addItem()}}>
                                        +
                                    </b> )
                                        
                                    ( <b onClick={() => {
                                        props.productRefs.current[props.productList.indexOf(props.productList.find(el => el.title === item.item))].current.removeItem()}}>
                                            -
                                    </b> ) */}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="comments-field">
                        <p></p>
                        <textarea className="order-comments" rows="4" placeholder="Add comment..."></textarea>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                <form>
                    {props.cartItems && (
                        <button className="popup btn btn-secondary btn-pay" onClick={(e) => sendPaymentRequest(e, props.cartService, props.saveOrderToDB, props.items)}>Pay €{totalPrice.toFixed(2)}</button>
                    )}
                </form>
                {/* <button className="popup btn btn-secondary" onClick={props.cartHide}>Close Cart</button> */}
                </Modal.Footer>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    counter: state.counter.counter,
    listAdded: state.counter.listAdded,
    items: state.cart.items,
});


export default connect(mapStateToProps)(CartInfoModal);