import eventBus from "./eventBus";

export default class ProductService {

    constructor() {
        this.productList = [];
        this.productItems = {};

        ProductService.productItems = {};
    }

    setProductList = (productList) => {
      this.productList = productList;
    };

    getProductList = () => {
        return this.productList;
    };

}