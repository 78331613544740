export default class Product {

    constructor(props) {
        this.id = props.id;
        this.state = {
            count:0,
            style:'',
            label:'Add to cart',
        }
    }

   

}