import { Link } from "react-router-dom";

export default function ResetPassword(props) {
    
    return(
        <>
            <section className={`login-popup visible`} onSubmit={props.resetPassword}>
                <form className="login-form">
                    <h2>Forgot password</h2>
                    {!props.linkSent && 
                        <>
                            Your email: <input type="email" value={props.usernameLog} onChange={(e) => props.setUsernameLog(e.target.value)} />
                            <button type="submit">Reset</button>
                        </>
                    }
                    {props.linkSent &&
                    <>
                        <p>Password reset link has been sent to your email.</p>
                    </>}
                    <p>
                        <Link to="/login">Back to login</Link>
                        <Link to="/">Home</Link>
                    </p>
                </form>
                {props.error && <p>{props.error}</p>}
            </section>
        </>
    );
}