const eventBus = {
    events: {},
    subscribe(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    },
    emit(event, data) {
        if (this.events[event]) {
            this.events[event].forEach(callback => {
                callback(data);
                const myEvent = new CustomEvent(event, { tag: 'dda' } );
                document.dispatchEvent(myEvent);
            });
        }
    },
    // on(eventName, fn) {
    //     document.addEventListener(eventName, fn);
    // },
};

export default eventBus;