import { combineReducers } from 'redux';
import CartService from '../services/cart-service';
import eventBus from '../services/eventBus';

const initialState = {
  counter: {},
  listAdded: [],
};

const counterReducer = (state = initialState, action) => {
  let c = null;
  let iid = null;
  switch (action.type) {
    case 'INCREMENT':

      if(!action.payload.id) {
        return {...state}
      }
      if(!Object.keys(state.counter).includes(action.payload.id.toString())) {
        let c = state.counter;
        c = 1;
        let iid = action.payload.id.toString();
        return { ...state, counter: {...state.counter, [iid]: c} };
      }
      c = state.counter[action.payload.id.toString()] + 1;
      iid = action.payload.id.toString();
      return { ...state, counter: {...state.counter, [iid]: c} };

    case 'DECREMENT':
      if(!action.payload.id) {
        return {...state}
      }
      if(!Object.keys(state.counter).includes(action.payload.id.toString())) {
        return { ...state };
      }
      c = state.counter[action.payload.id.toString()] - 1;
      iid = action.payload.id.toString();
      return { ...state, counter: {...state.counter, [iid]: c} };
    
    case 'ADDTOLIST':
      if(!action.payload.id) {
        return {...state}
      }
      if(state.listAdded.includes(action.payload.id.toString())) {
        return { ...state };
      }
      let l = state.listAdded;
      l = [...l, action.payload.id.toString()]
      return { ...state, listAdded: l };
  
    default:
      return state;
  }
};

const cartState = {
  items: [],

};
const cartReducer = (state = cartState, action) => {
  let items1 = null;
  switch (action.type) {    
    case 'ADDTOCART':
      if(!action.payload.id) {
        return {...state}
      }
      
      items1 = state.items;
      let found = false;

      items1 = items1.map((el, i) => {
        if(el.item === action.payload.item) {
          const obj = Object.assign({}, el);
          obj.count = el.count + 1;
          // el.count += 1;
          found = true;
          return obj;
          // return { ...state, items: items1 };
        } else {
          return el;
        }
      });
      if(!found) {
        // items1.push({product_id: action.payload.id,
        //             product_price: action.payload.price,
        //             key: action.payload.item,
        //             item: action.payload.item,
        //             count: action.payload.count,
        //             image: action.payload.image,
        //             stripe_token: action.payload.stripe_token});
        items1 = [...items1, {product_id: action.payload.id,
          product_price: action.payload.price,
          key: action.payload.item,
          item: action.payload.item,
          count: 1,
          image: action.payload.image,
          stripe_token: action.payload.stripe_token}];
      }

      eventBus.emit('productAdded', {price: action.payload.price});
      return { ...state, items: items1 };
      
    case "REMOVEFROMCART":
      items1 = state.items;
      let len = items1.length;
      let price = 0;
      for(let i = 0; i < len; i++) {
        if(items1[i].item === action.payload.title) {
          price = items1[i].product_price;
          
          if(items1[i].count > 1) {
            items1[i].count -= 1;
            break;
          } else {
            items1 = items1.filter(el => {return el.title != action.payload.title;})
            // items1.splice(i, 1);
            break;
          }
        }
      }

      
        // if(this.items.length == 0) {
        //   this.orderHide();
        // }
        eventBus.emit('productRemoved', {price: price});
        
      return { ...state, items: items1 };
    default:
      return state;
  }
};

// Combine Reducers
const rootReducer = combineReducers({
  counter: counterReducer,
  cart: cartReducer,
  // Add other reducers here if you have more
});

export default rootReducer;