import React from 'react';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';

export default class TagsPlaceholder extends React.Component { 


    render() {
        return (
            <span className={`tag skeleton`}></span>
        );
    }

}