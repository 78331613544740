import { Link, useParams } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';

export default function ForgotPassword(props) {

    const {userId, hashString} = useParams();
    const [passwordSaved, setPasswordSaved] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hide, setHide] = useState(false);
    const [error, setError] = useState('');

    const resetPassword = async () => {
        if(password == '') {
            setError('Password cannot be empty.');
            setTimeout(_ => {setError('');}, 1500);
            return;
        }

        if(password != confirmPassword) {
            setError('Passwords do not match.');
            setTimeout(_ => {setError('');}, 1500);
            return;
        }

        let url = '';
        if (process.env.NODE_ENV === 'production') {
            url = process.env.REACT_APP_PROD_API_URL;
        } else if (process.env.NODE_ENV === 'development') {
            url = process.env.REACT_APP_DEV_API_URL;
        }
        
        setHide(true);
        
        try {
            const res = await axios.post(url + '/user/set_password', { id: userId, hash: hashString, password: password })
            .then(response => {
                if (response.status === 200) {
                    setPasswordSaved(true);
                } else {
                    setPasswordSaved(false);
                }
                setHide(false);
            })
            .catch(error => {
                console.error(error);
                setHide(false);
            });
        } catch (err) {
            setHide(false);
        }
    };
    
    return (<>

        
      <div className='center'>
        {!passwordSaved && 
          <>
          <section className={`login-popup visible`} onSubmit={props.resetPassword}>
          <form className="login-form">
            <p>New password</p>
            <input type="password" onChange={(e) => setPassword(e.target.value)}/>
            <p>confirm password</p>
            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
            {!hide && 
                <button type="submit" className="btn-save" onClick={(e) => {e.preventDefault(); resetPassword();}}>Save</button>
            }
            {error != '' &&
            <>
                <p>{error}</p>
            </>}

          </form>
          </section>
          </>
        }
        {passwordSaved &&
          <>
            <h1>The password has been changed, you can now log in.</h1>
            <Link to="/">
                Go to home page
            </Link>
          </>
        }
      </div>
    </>);

}