import { Modal, Button } from "react-bootstrap";
import React, { useCallback, useRef, useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/css/modal.css';
import fullStar from '../assets/ui/fullStar.png';
import emptyStar from '../assets/ui/emptyStar.png';
import { Link, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

import TagItem from './TagItem';

import eventBus from "../services/eventBus";

import { connect } from "react-redux";

var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    // centerPadding: '100px',
  };

function useOutsideAlerter(ref, props) {
    const navigate = useNavigate();

    const redirect = useCallback(
        () => {window.history.replaceState(null, "Dr. Badi", "/" + (process.env.SUBFOLDER ? process.env.SUBFOLDER : ''))}, []
        // () => navigate('/drbadi-web-app', { replace: true }),
        // [navigate]
    );

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            redirect()
            props.hideModal();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


export function ProductInfoModal(props) {

    const [startPoint, setStartPoint] = useState(0);
    const [pullChange, setPullChange] = useState();
    const [started, setStarted] = useState(false);
    const [closed, setClosed] = useState(false);
    const [tooltipStyle, setTooltipStyle] = useState('none');

    const o_addToCart = props.addToCart;
    const o_increment = props.increment

    
    const pullStart = (e) => {
        if(document.querySelector('[role=dialog]').scrollTop <= 5) {
            const { screenY } = e.targetTouches[0];
            setStartPoint(screenY);
            setStarted(true);
        }
    }
      
    const pull = (e) => {
        if(!started) {
            return;
        }
        const touch = e.targetTouches[0];
        const { screenY } = touch;
        let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
        if(pullLength > 50 && pullLength < 220) {
            document.querySelector('div[role="dialog"]').style.transform = "translateY(" + pullLength + 'px)';
            document.querySelector('div[role="dialog"]').style.opacity = 100 / pullLength;
        }
        setPullChange(pullLength);
        console.log({ screenY, startPoint, pullLength, pullChange });
    }
    
    const pullEnd = () => {
        setStarted(false);
        setStartPoint(0);
        setPullChange(0);
        if (pullChange > 220) {
            props.hideModal();
        }
        
        if(pullChange > 20) {
            if(!closed) {
                setTimeout(_ => {
                    if(!closed) {
                        // document.querySelector('div[role="dialog"]').style.transform = "translateY(" + 100 + 'px)';
                        // document.querySelector('div[role="dialog"]').style.opacity = 1;
                    }
                }, 50);
            }
        }        

    }

    const navigate = useNavigate();

    const a = (props.userFavorites !== null && (props.userFavorites.find(obj => obj === props.productId) !== undefined));

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props);
    let rating_count = 0;
    let current_rating = 0;
    let overall_rating = 0;
    //props.productInfo.user_rating
    {props.reviewList && props.reviewList.map((iitem) => {if (iitem.product_id == props.productId) {
        rating_count += 1;
        current_rating += iitem.rating;
        overall_rating = current_rating / rating_count;
    }})}

    let free_text = props.productInfo.free_text ? props.productInfo.free_text.replace("\n", '<br />') : '';

    const updateFav = () => {
        if(!props.user) {
            setTooltipStyle('block');

            setTimeout(_ => {
                setTooltipStyle('none');
            }, 1500);
            return;
        }
        
        if(props.isFaved) {
            eventBus.emit('productLike', {id: props.productId, like: false});
            props.updateFav(false);
        } else {
            props.updateFav(true);
            eventBus.emit('productLike', {id: props.productId, like: true});
        }
    };

    const ratingChanged = (rating) => {
        props.ratingAdd(null, props.productId, rating);
    }

    const hideModal = () => {
        setClosed(true);
        props.hideModal();
        navigate("/" + (process.env.SUBFOLDER ? process.env.SUBFOLDER : ''));
    }

    const shareItem = async (props) => {
        const response = await fetch(props.productInfo.product_images.data[0].attributes.url);
        const blob = await response.blob();
        const filesArray = [
            new File(
                [blob],
                'product.jpg',
                {
                    type: "image/jpeg",
                    lastModified: new Date().getTime()
                }
            )
        ];

        const shareData = {
            title: props.productInfo.title,
            text: "Dr.Badi - " + props.productInfo.title,
            url: process.env.REACT_APP_FULL_URL + "/product/" + props.productInfo.title.replace(/\s/g, '-').substring(0, 100),
            files: filesArray,
          };
    
        //   const resultPara = document.querySelector(".result");
          
          // Share must be triggered by "user activation"
            try {
              await navigator.share(shareData);
            //   resultPara.textContent = "MDN shared successfully";
            } catch (err) {
            //   resultPara.textContent = `Error: ${err}`;
            }
    }

    const red = "#FF5D5E";
    const white = "#ffffff";

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta property="og:description" content={props.productInfo.details} />
                <meta property="og:image" content={(props.productInfo.product_images && props.productInfo.product_images.data.length > 0) ? props.productInfo.product_images.data[0].attributes.url : ''} />
                <meta property="og:title" content={props.productInfo.title} />
            </Helmet>

            <Modal show={props.show.status}>
                <div ref={wrapperRef} class="product-window" onTouchStart={pullStart} onTouchMove={pull} onTouchEnd={pullEnd}>
                    <Modal.Header>
                        <Modal.Title >

                        <div className="arrow">
                            <Link to={"/" + (process.env.SUBFOLDER ? process.env.SUBFOLDER : '')} onClick={hideModal}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.57 5.92999L3.5 12L9.57 18.07" stroke="#182243" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20.5 12H3.67" stroke="#182243" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                            </Link>
                            </div>
                            <span className="store-name">Dr.Badi</span>
                            <div className="share">
                                <a onClick={() => shareItem(props)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" enable-background="new 0 0 50 50">
                                        <path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z"/><path d="M24 7h2v21h-2z"/><path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z"/>
                                    </svg>
                                </a>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="image-wrapper">
                            <Slider {...settings}>
                                {
                                    props.productInfo.product_images !== undefined && props.productInfo.product_images.data.length > 0 && props.productInfo.product_images.data.map((el, idx) => {return (
                                        <img src={ props.productInfo.product_images.data != null ? el.attributes.url : ''}
                                        style={{backgroundImage: "url('')"}}
                                        alt={props.productInfo.title}
                                        className="modal-main-image"
                                        />
                                        )})
                                    }
                            </Slider>
                            { !props.productInfo.img && <span>image is empty</span>}
                        </div>

                        <section className="divider" style={{}}>
                            <section className="divided">
                            </section>
                        </section>

                        <section className="before_description">
                            <span className={`in-stock ${(props.productInfo.count == 0 || props.productInfo.count == 'pcs') ? 'out-of-stock' : ''}`}>{props.productInfo.count == 'pcs' ? 0 : props.productInfo.count} in stock</span>
                                <a className="like" onClick={() => updateFav(props.productId)}>
                                    <div className="app_tooltip" style={{display: tooltipStyle,}}>Login or signup to use favorites</div>
                                    <svg fill={ props.isFaved ? red : white} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.69001C2 5.60001 4.49 3.10001 7.56 3.10001C9.38 3.10001 10.99 3.98001 12 5.34001C13.01 3.98001 14.63 3.10001 16.44 3.10001C19.51 3.10001 22 5.60001 22 8.69001C22 15.69 15.52 19.82 12.62 20.81Z" stroke={props.isFaved ? red : "#D1D1D1"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg> 
                                </a>
                        </section>
                        <header>
                            <span className="brand">
                                {props.productInfo.product_brand}
                            </span>
                            <section className="title-info">
                                <span className="product-title">
                                    {
                                        props.productInfo.title &&
                                        <span>{props.productInfo.title}</span>
                                    }
                                    { !props.productInfo.title && <span>no product select</span> }
                                <div className="weight">
                                    {props.productInfo.unit} {props.productInfo.unit_label}
                                </div>
                                </span>
                                <div>
                                    <div className="price-block">
                                        {props.productInfo.discount_price &&
                                            <span className="old-price">
                                                €{props.productInfo.discount_price}
                                            </span>
                                        }
                                        <span className="price">
                                            €{props.productInfo.price}
                                        </span>
                                    </div>

                                    <section className="product-rating">
                                        <ReactStars
                                            count={5}
                                            // value={overall_rating}
                                            value={props.productInfo.rating}
                                            size={24}
                                            emptyIcon={<img src={emptyStar}/>}
                                            filledIcon={<img src={fullStar}/>}
                                            onChange={ratingChanged}
                                            activeColor="#ffd700"
                                            edit={props.user ? true : false}
                                            /> <span class="rating-count">{rating_count}</span>
                                    </section>
                                </div>
                            </section>

                            <section className="tags">
                                {
                                    props.productInfo.product_tags && props.productInfo.product_tags.map((e) => {
                                        return (
                                            <TagItem updateTags={props.updateTags} hideModel={props.hideModal} text={e} modal={true} />
                                            );
                                        })
                                    }
                            </section>
                        </header>
                        <main>
                            <p className="text-bold description-title">Description</p>
                            <div className="product-description">
                                { props.productInfo.details && <span>{props.productInfo.details}</span>}
                                { !props.productInfo.details && <span>details empty</span>}
                            </div>
                            <br/>
                            
                            <p className="text-bold nutition-table-title">Nutritional Facts</p>
                            <section className="nutrition-table">
                                <header className="nutrition-header">
                                    <span>Typical values</span>
                                    <span>Per 100 g</span>
                                </header>
                                <div className="nutrition-info">
                                    {props.productInfo.nutrition_table && props.productInfo.nutrition_table.map((answer, i) => {     
                                        return (
                                            <p>
                                                <span>{answer.split(":")[0]}</span>
                                                <span className="value-column">{answer.split(":")[1]}</span>
                                            </p>
                                        )
                                    })}
                                </div>
                            </section>

                            <section className="extra-info">
                                <p dangerouslySetInnerHTML={{__html: free_text}}></p>
                            </section>

                            {/* { props.user && <div className="comments-field">
                                <p>Leave a review</p>
                                <textarea className="order-comments" onChange={(e) => props.setReview(e.target.value)}></textarea>
                                <button type="submit" onClick={(e) => props.reviewAdd(e, props.productId)}>Send</button>
                            </div> } */}

                            { props.productInfo.count > 0 && <div>
                                <button className="popup btn btn-primary modal-add-to-cart" type="button" onClick={() => {
                                    // eventBus.emit('productAdd', {id: props.productId});
                                    o_addToCart(props.productInfo.mapping_id, props.productInfo.price, props.productInfo.title,  props.productInfo.product_images.data[0].attributes.url, props.productInfo.stripe_token);
                                    // this.o_addToCart(this.props.mapping_productId, this.props.price, this., 1, this.props.stripe_token.split('_')[1] );
                                    o_increment(props.productInfo.mapping_id);
                                    hideModal();
                                }
                            }>Add to cart</button>
                            </div> }
                        </main>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    addToCart: (id, price, title, image, stripe_token) => {
        dispatch({ type: "ADDTOCART", payload: {id: id, price: price, item: title, image: image, stripe_token: stripe_token} });
        // dispatch({ type: "ADDTOCART", payload: {id: id} });
        // dispatch({ type: "ADDTOCART", payload: { } });
    },
    increment: (id) => {
        dispatch({ type: "INCREMENT", payload: {id: id}})
    },
});

const mapStateToProps = (state) => ({
    counter: state.counter.counter,
    listAdded: state.counter.listAdded,
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoModal);