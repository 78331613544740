import eventBus from "./eventBus";

export default class CartService {

    constructor(orderShow, orderHide) {
        this.items = [];
        this.orderShow = orderShow;
        this.orderHide = orderHide;
        this.scrolled = false;
        CartService.subscribedd = [];

        CartService.subscribedTo = (id) => {
          return CartService.subscribedd.includes(id);
        }
    
        CartService.subscribe = (id) => {
          let i = CartService.subscribedd;
          i.push(id);
          CartService.subscribedd = i;
        }
    }

    addToCart = (id, price, item, count, image="", stripe_token="") => {
      this.orderShow();
      let items1 = this.items;
        
      let found = false;
      items1.forEach(el => {
        if(el.item === item) {
          el.count += count;
          found = true;
          return;
        }
      });
      if(!found) {
        items1.push({product_id: id, product_price: price, key: item, item: item, count: count, image: image, stripe_token: stripe_token});
      }

      console.log("Added!");
      console.log(this.items);
      
      this.items = items1;
      eventBus.emit('productAdded', {price: price});
    };

    removeFromCart = (item, count) => {
        let items1 = this.items;
        let len = items1.length;
        let price = 0;
        for(let i = 0; i < len; i++) {
          if(items1[i].item === item) {
            price = items1[i].product_price;
            if(items1[i].count > 1) {
              items1[i].count -= count;
              break;
            } else {
              items1.splice(i, 1);
              break;
            }
          }
        }

        this.items = items1;

        console.log("Product removed!");
        console.log(this.items);
        if(this.items.length == 0) {
          this.orderHide();
        }

        eventBus.emit('productRemoved', {price: price});
    };
    

    getItems = () => {
        return this.items;
    }

    getPrepedItems = () => {
      return this.items;
    }

    hasItem(title = '') {
      console.log("title = ", title);
      console.log("Reading:");
      console.log(this.items);
      let items1 = this.items;
      console.log("items1:");
      console.log(items1);
      let found = false;
      items1.forEach(el => {
          console.log(el);
          if(el.item == title) {
            found = true;
          }
      });
      return found;
    }

}